<template>
  <div id="Home">
<!--    <Menu />-->
    <NewMenu />
    <FloatAction/>
    <section id="HomeSection">
      <Banner/>
      <Problemas/>
    </section>
    <ModeloBanner/>
    <Modelo8x8/>
    <ModeloLean/>
    <StrategicAdvisor/>
    <Programas/>
    <DesarrolloEmpresarial :completed="true" />
    <Diferenciadores/>
    <Puente/>
    <ComunidadBanner/>
    <EquipoBanner/>
    <Equipo/>
    <Experiencia/>
    <Testimonios/>
    <QuienesSomosBanner/>
    <QuienesSomos/>
    <Valores/>
    <NosPreocupamos/>
    <Contacto/>
    <Footer/>
  </div>
</template>


<script>
export default {
  components: {
    Footer: () => import(/* webpackChunkName: "Footer" */'../components/layout/Footer'),
    Contacto: () => import('../components/home/Contacto'),
    FloatAction: () => import('../components/layout/FloatAction'),
    // Menu: () => import('../components/layout/Menu'),
    NewMenu: () => import('../components/layout/NewMenu'),
    Banner: () => import('../components/banners/Banner'),
    NosPreocupamos: () => import(/* webpackChunkName: "NosPreocupamos" */'../components/quienes-somos/NosPreocupamos'),
    Valores: () => import(/* webpackChunkName: "Valores" */'../components/quienes-somos/Valores'),
    QuienesSomos: () => import(/* webpackChunkName: "QuienesSomos" */'../components/quienes-somos/QuienesSomos'),
    QuienesSomosBanner: () => import(/* webpackChunkName: "QuienesSomos" */'../components/quienes-somos/QuienesSomosBanner'),

    Problemas: () => import(/* webpackChunkName: "QuienesSomos" */'../components/challenges/Problemas'),
    ModeloBanner: () => import(/* webpackChunkName: "ModeloBanner" */'../components/models-programs/ModeloBanner'),
    Modelo8x8: () => import(/* webpackChunkName: "Modelo8x8" */'../components/models-programs/Modelo8x8'),
    ModeloLean: () => import(/* webpackChunkName: "ModeloLean" */'../components/models-programs/ModeloLean'),
    StrategicAdvisor: () => import(/* webpackChunkName: "StrategicAdvisor" */'../components/models-programs/StrategicAdvisor'),
    Programas: () => import(/* webpackChunkName: "Programas" */'../components/models-programs/Programas'),
    DesarrolloEmpresarial: () => import(/* webpackChunkName: "DesarrolloEmpresarial" */'../components/models-programs/DesarrolloEmpresarial'),
    Diferenciadores: () => import(/* webpackChunkName: "Diferenciadores" */'../components/models-programs/Diferenciadores'),
    ComunidadBanner: () => import(/* webpackChunkName: "ComunidadBanner" */'../components/models-programs/ComunidadBanner'),
    Puente: () => import(/* webpackChunkName: "Puente" */'../components/models-programs/Puente'),
    EquipoBanner: () => import(/* webpackChunkName: "EquipoBanner" */'../components/equipo-experiencia/EquipoBanner'),
    Equipo: () => import(/* webpackChunkName: "Equipo" */'../components/equipo-experiencia/Equipo'),
    Experiencia: () => import(/* webpackChunkName: "Experiencia" */'../components/equipo-experiencia/Experiencia'),
    Testimonios: () => import(/* webpackChunkName: "Testimonios" */'../components/equipo-experiencia/Testimonios'),
  },
  data() {
    return {};
  },
  methods: {},
  created() {
  },

  mounted() {
  },

  watch: {},
};
</script>
<style>

/* body, html {
  overflow-x: hidden;
} */
</style>
